import React,{Component} from 'react';
import { AddToHomeScreen } from 'react-pwa-add-to-homescreen';
import './App.css';

class App extends Component {
    constructor(props) {
        super(props);
        this.state= {
            list : [
                'Ja',
                'Po',
                'Awo',
                'Si',
                'Yes',
                'Ayo',
                'Bæli',
                'Bai',
                'Ya',
                'Ja',
                'Yes',
                'Jah',
                'Ite',
                'Oui',
                'Nai’',
                'diakh',
                'Yes',
                'Oui',
                'Haa',
                'Igen',
                'Jà',
                'Ары',
                'Эйе',
                'हाँ',
                'Iå',
                'Да',
                'ХIаъ',
                'ʔę́n',
                'بله',
                'Sí',
                'Hêe',
                'Ναι',
                'Ээ',
                'Тиим',
                'Balé',
                'हो',
                'Ҳa',
                'Бале',
                'Бен',
                'ھەئە',
                'Yebo',
                'Evet',
            ],
            current: 'Yes',
        };
    }

    componentDidMount() {
        this.interval = setInterval(() => this.setState({ current: this.state.list[Math.floor(Math.random() * 42)] }), 1000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render(){
        return (
            <span className="wrapper">
                <div className="container">
                    <h3 className="subtitle">Can we go out ?</h3>
                    <h1>{this.state.current}</h1>
                    <h2>
                        {/*
                        <a href="#" target="_blank" rel="noopener noreferrer">
                            <span className="website">Add to Home screen</span>
                        </a>
                        */}
                        <AddToHomeScreen />
                    </h2>
                </div>
                <p className="footer">© 2020 - <a href="#" target="_blank" rel="noopener noreferrer">Miguel</a> </p>
            </span>
        );
    }
}

export default App;
